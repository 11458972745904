<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />

    <v-card class="card">
      <v-card-title class="heading justify-center">Academic Dashboard</v-card-title>
      <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Organization</label>
            <v-autocomplete v-model="selected_org" placeholder="Select Organization" :items="org_list" item-text="name"
              item-value="id" outlined dense class="text" required @change="onload()"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Academic year</label>
            <v-autocomplete v-model="selected_ay" placeholder="Select Academic Year" :items="aylist" item-text="ay"
              item-value="id" outlined dense class="text" required></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Program Type</label>
            <v-autocomplete v-model="selected_program_type" placeholder="Select Prgram Type" :items="porgram_type_list"
              item-text="name" item-value="id" outlined dense class="text" required
              @change="getprogram()"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Department</label>
            <v-autocomplete v-model="selected_program" placeholder="Select Department" :items="program_list"
              item-text="name" item-value="id" outlined dense class="text" required></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Semester</label>
            <v-autocomplete v-model="selected_sem" placeholder="Select Semester" :items="sem_list" item-text="sem"
              item-value="id" outlined dense class="text" required></v-autocomplete>
          </v-col>
        </v-row>
        <v-row> </v-row>
        <div>
          <center>
            <v-btn color="success" class="mb-5" @click="get_academic_dashboard_data()">Fetch Data</v-btn>
          </center>
        </div>
      </v-card-text>
      </div>
      <v-row justify="end" v-if="Array.isArray(tabledata) && tabledata.length" class="mr-5 my-5"><v-btn class="green" dark
          @click="exportF()">EXPORT</v-btn></v-row>
      <v-simple-table id="exceltable" v-if="Array.isArray(tabledata) && tabledata.length">
        <thead>
          <tr>
            <th>Srno</th>
            <th>Program</th>
            <th>Shift</th>
            <th v-for="(item, index) in tabledata[0].year_data" :key="index">
              {{ item.year_name }}
            </th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tabledata" :key="index">
            <td>{{ item.sr_no }}</td>
            <td>{{ item.program }}</td>
            <td>{{ item.shift }}</td>
            <td v-for="(item1, index1) in item.year_data" :key="index1">
              <v-chip color="grey white--text" @click="display_all_student_list(item1.student)">
                {{ item1.year_value }}
              </v-chip>
            </td>

            <td> <v-chip color="grey white--text" @click="display_total_student_list(item)">{{ item.total }}</v-chip></td>

          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="student_details_dialog">
      <v-card>
        <v-row justify="end" align="right">
          <v-col cols="12" sm="3" md="3">
            <v-text-field class="ma-5" v-model="search1" append-icon="mdi-magnify" label="Search" single-line
              hide-details></v-text-field>
          </v-col>
          <v-col class="mt-5" cols="12" sm="1" md="1">
            <template>
              <download-excel :data="student_data" :fields="excel_data" worksheet="Books Data" name="Student_details.xls">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                  </template>
                  <span>Export to Excel</span>
                </v-tooltip>
              </download-excel>
            </template>
          </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="student_data" :search="search1">

        </v-data-table>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { table2excel } from "../jquery.table2excel";
import axios from "axios";
import "@/assets/css/color.css";
export default {
  data: () => ({
    student_details_dialog: false,
    overlay: false,
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    init_loading: false,
    search: "",
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Reports',
        disabled: false,
        href: '/reports',
      },
      {
        text: 'Academic Dashboard',
        disabled: true,
        href: '',
      },
    ],
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "PRN", value: "prn" },
      { text: "Name", value: "name" },
      { text: "Rollno", value: "rollno" },
      { text: "Program", value: "program" },
      { text: "Division", value: "division" },
    ],
    excel_data: {
      "Sr. No.": "srno",
      "prn": "prn",
      "name": "name",
      "rollno": "rollno",
      "program": "program",
      "division": "division",
    },
    sem_list: [],
    porgram_type_list: [],
    aylist: [],
    program_list: [],
    book_dialog: false,
    search1: "",
    selected_ay: "",
    selected_program_type: "",
    selected_program: "",
    selected_sem: "",
    student_data: [],
    org_list: [],
    selected_org: "",
  }),

  mounted() {
    this.init();
  },
  methods: {
    exportF() {

      $("#exceltable").table2excel({
        name: "Worksheet Name",
        filename: "acedemicdashboard", //do not include extension
        fileext: ".xls" // file extension
      });
    },
    display_total_student_list(item) {
      var total_array = [];
      console.log(item);
      for (var x in item.year_data) {
        for (var y in item.year_data[x].student) {
          total_array.push(item.year_data[x].student[y]);
        }
      }
      this.display_all_student_list(total_array);
    },
    display_all_student_list(value) {
      (this.student_data = []), console.log(value);
      var params = { student_list: value };
      axios
        .post("/Management/display_all_student_list", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.student_data = res.data.student_data;
            this.student_details_dialog = true;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
            this.overlay = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    get_academic_dashboard_data() {
      if(!this.selected_org)
      {
          this.showSnackbar("#b71c1c", "Please Select Organization");
          return;
      }
      var params = {
        orgid: this.selected_org,
        program_type: this.selected_program_type,
        program: this.selected_program,
        sem: this.selected_sem,
        ay: this.selected_ay,
      };
      this.init_loading = true;
      axios
        .post("/Management/get_academic_dashboard_data", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.tabledata = res.data.academic_data;
            console.log(this.tabledata);
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
            this.init_loading = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        })
        .finally(() => {
          this.init_loading = false;
        });
    },
    init()
    {
        this.init_loading = true;
        axios
            .post("/ImportData/getOrgList")
            .then((res) => {
                if (res.data.msg == "200") {
                    this.org_list=res.data.org_list;
                    this.selected_org=res.data.org;
                    this.onload();
                }
            })
    },
    onload() {
      if(!this.selected_org)
      {
          this.showSnackbar("#b71c1c", "Please Select Organization");
          return;
      }
      var params={"org":this.selected_org};
      this.init_loading = true;
      axios
        .post("/Librarian/getbook_bank_report_data", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.aylist = res.data.aylist;
            this.selected_ay = res.data.current_ay.id;

            this.sem_list = res.data.sem_list;
            this.policy_type_list = res.data.policy_type_list;

            this.selected_division = "ALL";
            this.porgram_type_list = res.data.porgram_type_list;
            this.shift_list = res.data.shift_list;

            this.selected_program_type = this.porgram_type_list[0].id;
            this.getprogram();
            this.sem_list.push({ id: "ALL", sem: "ALL" });
            this.selected_sem = "ALL";

            this.shift_list.push({ id: "ALL", shift: "ALL" });
            this.selected_shift = "ALL";
            this.selected_policy_type = this.policy_type_list[0].id;
            this.selected_program = "ALL";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", error); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        })
        .finally(() => {
          this.init_loading = false;
        });
    },
    getprogram() {
      var params = { programtypeid: this.selected_program_type };
      axios
        .post("/Librarian/getprogram", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.program_list = res.data.programlist;
            this.program_list.push({ id: "ALL", name: "ALL" });
            this.selected_program = "ALL";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.add-section {
  display: block;
  margin: 1rem;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}

.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}

.checkbox .box {
  margin-top: 5px;
}

.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
